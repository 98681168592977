import React from "react";
import { StaticImage } from "gatsby-plugin-image"

//A component that displays the author of a blog post in tailwindcss

export default function Author({ date }) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };


    return (
        <div>
            <div className="text-left flex flex-row w-auto space-x-1">
                <StaticImage src="../../images/avatar.png" alt="Lucas Rosvall fiskar" className="sm:!block !rounded-full !w-12 !h-12 my-auto border border-gray-500" imgStyle={{ borderRadius: '100%', width: '48px', height: '48px' }} />
                <div>
                    <div className="!text-sm ml-2 mb-0 italic text-gray-800">Publicerad av Lucas Rosvall</div>
                    <div className="!text-sm ml-2  italic text-gray-800 "> den {date && typeof date == "string" ? <time dateTime={date}>{new Date(date).toLocaleDateString('sv-SV', options)}</time> : <time dateTime={date}>{date.toLocaleDateString('sv-SV', options)}</time>}
                    </div>
                </div>
            </div>
        </div>
    );
}
